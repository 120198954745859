<template>
  https://medium.com/swlh/why-i-wont-be-purchasing-tailwind-ui-but-maybe-you-should-3fb1ed3b6c35
  <div
    class="relative font-medium md:h-screen flex items-center content-center"
  >
    <div class="mx-auto w-full">
      <div class="w-full max-w-md mr-auto ml-auto mt-4 mb-1 text-center">
        <h1 class="text-gray-800 block text-3xl font-extrabold font-title">
          Logga in som medlem
        </h1>
      </div>
      <div
        class="w-full max-w-md mr-auto ml-auto mt-43 mb-6 text-center font-medium text-sm"
      >
        Or
        <a
          href="#"
          class="text-indigo-600 hover:text-indigo-800 duration-200 transition-all"
          >start your 14-day free trial</a
        >
      </div>
      <div class="w-full max-w-md mr-auto ml-auto mt-4">
        <div class="bg-white shadow rounded-md px-8 py-8 mb-4 ml-auto mr-auto">
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-medium mb-2"
              for="username"
            >
              Email address
            </label>
            <input
              class="shadow-sm appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:border-indigo-300"
              id="username"
              type="text"
              placeholder="user@example.com"
            />
          </div>
          <div class="mb-6">
            <label
              class="block text-gray-700 text-sm font-medium mb-2"
              for="username"
            >
              Password
            </label>
            <input
              class="shadow-sm appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-600 text-sm leading-tight focus:outline-none focus:border-indigo-300"
              id="password"
              type="password"
              placeholder="***************"
            />
          </div>
          <div class="mb-6">
            <div class="grid sm:grid-cols-2 gap-4">
              <div class="text-center sm:text-left">
                <label>
                  <input type="checkbox" class="mr-2" />
                  <span class="text-sm font-medium text-gray-700 "
                    >Remember me</span
                  >
                </label>
              </div>
              <div class="text-center sm:text-right">
                <a
                  href="#"
                  class="text-indigo-600 font-medium text-sm duration-200 transition-colors hover:text-indigo-800"
                  >Forgot your password?</a
                >
              </div>
            </div>
          </div>
          <div class="mb-6">
            <button
              type="button"
              class="bg-indigo-600 hover:bg-indigo-400 shadow-sm text-white font-semibold text-sm py-3 px-0 rounded text-center w-full hover:bg-tertiary duration-200 transition-all"
            >
              Sign in
            </button>
          </div>
          <div class="grid sm:grid-cols-3 gap-0 mb-6">
            <hr class="mt-3 hidden sm:block border-gray-400" />
            <span class="text-center bg-white text-sm text-gray-700 font-normal"
              >Or continue with</span
            >
            <hr class="mt-3 hidden sm:block border-gray-400" />
          </div>
          <div class="grid grid-cols-3 gap-2">
            <button
              type="button"
              class="relative border-solid border shadow-sm border-gray-400
                        font-semibold text-gray-600 text-sm py-1 text-center rounded w-full
                        focus:outline-none hover:border-indigo-600 transition-all duration-200"
            >
              <i class="pi pi-google text-lg" style="line-height: 28px;"></i>
            </button>
            <button
              type="button"
              class="relative border-solid border shadow-sm border-gray-400
                        font-semibold text-gray-600 text-sm py-1 px-0 rounded text-center w-full
                        focus:outline-none hover:border-indigo-600 transition-all duration-200"
            >
              <i class="pi pi-twitter text-lg" style="line-height: 28px;"></i>
            </button>
            <button
              type="button"
              class="relative border-solid border shadow-sm border-gray-400
                        font-semibold text-gray-600 text-sm py-1 px-0 rounded text-center w-full
                        focus:outline-none hover:border-indigo-600 transition-all duration-200"
            >
              <i class="pi pi-linkedin text-lg" style="line-height: 28px;"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-for="(c, i) in [/*'blueGray', */'coolGray', /* 'gray',*/ /*'trueGray'*/ /*, 'warmGray'*/]"
    :key="i"
  >
    {{ c }}
    <div
      v-for="(s, j) in ['900']"
      :key="j"
      :class="['w-screen', 'border-2', 'bg-' + c + '-' + s]"
    >
      {{ 'bg-' + c + '-' + s }}
      <img
        title="Sveriges Speleologförbund"
        class="m-10 w-32 h-32"
        src="@/assets/ssf_logo_color_print.svg"
      />
      <div
        class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl  mb-40"
      >
        <div class="md:flex">
          <div class="md:flex-shrink-0">
            <img
              class="h-48 w-full object-cover md:w-48"
              src="/img/store.jpg"
              alt="Man looking at item at a store"
            />
          </div>
          <div class="p-8">
            <div
              class="uppercase tracking-wide text-sm text-indigo-500 font-semibold"
            >
              Case study
            </div>
            <a
              href="#"
              class="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
              >Finding customers for your new business</a
            >
            <p class="mt-2 text-gray-500">
              Getting a new business off the ground is a lot of hard work. Here
              are five ideas you can use to find your first customers.
            </p>
          </div>
        </div>
      </div>

      <Card v-for="(l, k) in [100,200]" :key="k" :class="'bg-'+c+'-'+(+s-l)+' mb-40'">
				<template #content>
          {{ l }}
          <span class="text-txtaccent font-bold">grottor.se</span><br>
          <span class="text-txtprimary">
          Det här är en text i färg 2.<br>
          Backgrunden är {{ 'bg-'+c+'-'+(+s-l) }}.<br><br>
          </span>
        <div
          class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl  mb-40"
        >
          <div class="md:flex">
            <div class="md:flex-shrink-0">
              <img
                class="h-48 w-full object-cover md:w-48"
                src="/img/store.jpg"
                alt="Man looking at item at a store"
              />
            </div>
            <div class="p-8">
              <div
                class="uppercase tracking-wide text-sm text-indigo-500 font-semibold"
              >
                Case study
              </div>
              <a
                href="#"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
                >Finding customers for your new business</a
              >
              <p class="mt-2 text-gray-500">
                Getting a new business off the ground is a lot of hard work.
                Here are five ideas you can use to find your first customers.
              </p>
            </div>
          </div>
        </div>
				</template>
      </Card>
    </div>
  </div>
</template>
<script>
export default {
  name: "PocView",
  props: {
    msg: String
  }
};
</script>
<style lanf="scss">
</style>
